<div class="auth-layout">
    <div class="auth-layout__left">
        <img src="../assets/logo-white.png" height="79" width="150" alt="tweb logo" />
    </div>
    <div class="auth-layout__right" *ngIf="registering">
        <div class="auth-layout__right__children">
            <div class="auth-layout__form-container">
                <h2 class="auth-layout__h2">Congratulations - You are Eligible!</h2>
                <p>
                    Congratulations! You passed the prescreen! You can proceed to completing the main application;
                    Start by setting up your personal account.
                </p>
                <br />
                <app-dynamic-form
                    *ngIf="config.formObject && config.formObject.length"
                    [config]="config.formObject"
                    ([isEditing])="(isEditing)"
                    (submitted)="formSubmitted($event)"
                >
                    <input
                        #honeypot
                        type="checkbox"
                        name="opt_in_to_emails"
                        value="1"
                        style="display: none !important"
                        tabindex="-1"
                        autocomplete="off"
                    />
                    <br />
                    <div style="margin-top: 24px; margin-bottom: 32px">
                        <p>
                            By creating an account, you have read and agree to our
                            <a
                                href="https://www.theworldeggandspermbank.com/wp-content/uploads/2018/05/terms-of-use.pdf"
                                rel="noopener noreferrer"
                                target="_blank"
                                >terms and conditions</a
                            >.
                        </p>
                    </div>
                    <button
                        type="submit"
                        mt-doubleClickDisabled
                        [(doubleClickIsDisabled)]="doubleClickIsDisabled"
                        Class="margin-bottom__xl button button--primary"
                    >
                        Register
                    </button>
                </app-dynamic-form>
            </div>
        </div>
        <div class="auth-layout__footer">
            <div class="auth-layout__footer">
                <a
                    href="https://www.theworldeggandspermbank.com/wp-content/uploads/2018/05/terms-of-use.pdf"
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    Terms of Use
                </a>
                | <a href="https://www.theworldeggandspermbank.com/contact-us/" target="_blank">Contact Us</a>
            </div>
        </div>
    </div>
    <div class="auth-layout__right" *ngIf="registerSuccess">
        <div class="auth-layout__right__children">
            <div class="auth-layout__form-container">
                <h2 class="auth-layout__h2">Thank you for registering! You’re almost there!</h2>
                <p>
                    You will recieve a welcome email and an email verification shortly. Please add
                    <strong class="brandPurple">donors@twesb.com</strong> to your email contacts to ensure this email and future communications do not
                    go to spam.
                </p>
                <br />
                <p>Please take a moment to verify your email where you will then automatically be taken to our main application, to FINISH YOUR REGISTRATION!</p>
            </div>
        </div>
    </div>
</div>
